<template>
  <div
    class="card h-full has-border-radius shadow-none has-background-white-ter p-4"
    :class="{
      'has-background-primary-04': requisito.id == null
    }"
  >
    <div
      class="flex flex-col items-center justify-center h-full"
      v-if="requisito.id == null"
    >
      <b-image src="/images/requisito_carpeta.svg" alt="carpeta" />
      <p class="font-bold text-3xl mt-8">Selecciona un requisito</p>
      <p>Para ver sus detalles</p>
    </div>
    <div v-else class="text-left">
      <p class="text-2xl font-bold" v-if="!hideTitle">
        {{ requisito.requisito.nombre }}
      </p>
      <p class="has-text-grey-light text-lg mt-4" v-if="!status_toggled">
        Estado
      </p>
      <p
        class="flex items-center cursor-pointer"
        @click="() => (status_toggled = !status_toggled)"
        v-if="!status_toggled"
      >
        <b-icon
          :icon="iconProperties(requisito.status).icon"
          :pack="iconProperties(requisito.status).pack"
          :size="iconProperties(requisito.status).size"
          :class="iconProperties(requisito.status).class"
        />
        <span
          class="text-2xl font-bold ml-3"
          :class="iconProperties(requisito.status).color"
          >{{
            requisito.status ? requisito.status.nombre : "Sin estatus"
          }}</span
        >
        <b-icon icon="chevron-down" size="is-small" class="ml-2" />
      </p>
      <valid-auto-complete
        v-if="status_toggled"
        label="Estado"
        placeholder="Seleccionar..."
        v-model="new_status"
        :data="allStatus"
        :open-on-focus="true"
        show="nombre"
        :expanded="true"
        :custom="true"
        @select="changeStatus"
      >
        >
        <template v-slot:custom="slotProps">
          <div class="flex items-center">
            <b-icon
              :icon="iconProperties(slotProps.option.option).icon"
              :pack="iconProperties(slotProps.option.option).pack"
              :size="iconProperties(slotProps.option.option).size"
              :class="iconProperties(slotProps.option.option).class"
            />
            <span
              class="text-xl ml-2"
              :class="iconProperties(slotProps.option.option).color"
              >{{ slotProps.option.option.nombre }}</span
            >
          </div>
        </template>
      </valid-auto-complete>
      <p class="has-text-grey-light text-lg mt-4" v-if="requisito.motivo">
        Motivo
      </p>
      <p v-if="requisito.motivo">{{ requisito.motivo }}</p>
      <p class="has-text-grey-light mt-2 text-lg" v-if="!hideDescription">
        Descripción
      </p>
      <p v-if="!hideDescription">{{ requisito.requisito.descripcion }}</p>
      <p class="has-text-grey-light mt-4 text-lg">Documentos digitalizados</p>
      <documentos
        :documentos="requisito.files"
        @desligar="desligarDocumento"
        tipo="requisitos_supervision"
        @togglecf="toggleCapvitalFiles"
        :key="reload_docs"
        :small="true"
        :archivosObligatorios="requisito.archivo_obligatorio == 1"
        :requisito_id="requisito.id"
      />
      <div v-if="!hideComments">
        <p class="has-text-grey-light mt-4 text-lg">Comentarios</p>
        <comentarios
          class="pt-2"
          :comentarios="requisito.comments"
          :small="true"
          :key="reload_comments"
          @comentar="enviarComentario"
          tipo="requisito"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Documentos from "@/components/generals/Documentos";
import Comentarios from "@/components/generals/Comentarios";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import { mapGetters } from "vuex";

export default {
  name: "SupervisionSidebar",
  props: {
    requisito: Object,
    tipo: String,
    hideComments: {
      type: Boolean,
      default: false
    },
    hideDescription: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    allStatus: Array
  },
  provide() {
    return {
      linea_has_requisito_id: this.requisito.id
    }
  },
  components: {
    Documentos,
    Comentarios,
    ValidAutoComplete
  },
  data() {
    return {
      status_toggled: false,
      new_status: null,
      reload_docs: 0,
      reload_comments: 0
    };
  },
  computed: {
    ...mapGetters("files", ["getStatus"])
  },
  watch: {
    getStatus() {
      this.reload_docs += 1;
    },
    requisito() {
      this.reload_comments += 1;
    }
  },
  methods: {
    /*
      Cambia el status del requisito
      - @status (Object): Nuevo status que se asignará al requisito
    */
    changeStatus(status) {
      this.$buefy.dialog.prompt({
        title: "Cambiar requisito a " + status.nombre,
        message:
          "¿Estas seguro que deseas cambiar el status del requisito a " +
          status.nombre +
          "? ¿Cual es el motivo?",
        inputAttrs: {
          placeholder: "Motivo del cambio...",
          maxlength: 255
        },
        confirmText: "Cambiar status",
        cancelText: "Cancelar",
        type: "is-primary",
        onConfirm: motivo =>
          this.$emit("cambiar-status", {
            id: this.requisito.id,
            status: status.id,
            motivo: motivo
          })
      });
      this.status_toggled = false;
    },
    /*
      - @id (Integer): Id del documento a desligar
    */
    desligarDocumento(id) {
      this.$emit("desligar-doc", { file_id: id, id: this.requisito.id });
    },
    // Activa Capvital Files con la información necesaria del objeto a agregar documentos
    toggleCapvitalFiles() {
      this.$emit("toggle-cf", {
        id: this.requisito.id,
        status: true
      });
    },
    enviarComentario(data) {
      data.requisito_id = this.requisito.id;
      this.$emit("enviar-comentario", data);
    },
    /* 
      Regresa las propiedades del icono dependiendo del status del requisito  
      - @status (Object): Status actual del requisito
    */
    iconProperties(status) {
      if (status == null || status.id == null) {
        return {
          icon: "circle",
          pack: "far",
          size: "is-medium",
          color: "has-text-grey"
        };
      }
      switch (status.nombre) {
        case "Correcto":
        case "Aceptado":
        case "Reportado a organo supervisor":
          return {
            icon: "check-circle",
            size: "is-medium",
            pack: "fas",
            class: "has-text-success",
            color: "has-text-success"
          };
        case "Seguimiento":
          return {
            icon: "sync",
            size: "is-small",
            pack: "fas",
            class:
              "p-4 has-background-warning rounded-full has-text-grey-darker",
            color: "has-text-warning"
          };
        case "Negativo":
        case "Rechazado":
          return {
            icon: "times-circle",
            class: "has-text-danger",
            pack: "fas",
            size: "is-medium",
            color: "has-text-danger"
          };
        case "En revisión":
          return {
            icon: "info-circle",
            class: "has-text-info",
            pack: "fas",
            size: "is-medium",
            color: "has-text-info"
          };
        case "Modificado":
          return {
            icon: "exclamation-circle",
            class: "has-text-warning",
            pack: "fas",
            size: "is-medium",
            color: "has-text-warning"
          };
        default:
          return {
            icon: "circle",
            pack: "far",
            size: "is-medium",
            color: "has-text-grey"
          };
      }
    }
  }
};
</script>
